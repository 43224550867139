import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "setup-oidc"
    }}>{`Setup OIDC`}</h1>
    <p>{`Ocelot can integrate with any `}<a parentName="p" {...{
        "href": "https://openid.net/specs/openid-connect-core-1_0.html"
      }}>{`Open ID Connect (OIDC)`}</a>{` Identity
Provider; this will allow your users to log on to your application using the credentials they already possess and use.`}</p>
    <h2 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h2>
    <p><img alt="Ocelot and OIDC" src={require("../drawings/oidc.svg")} /></p>
    <p>{`As you can see from the diagram the user tries to perform a specific business operation through the ´POST /api/transfer´.
Ocelot finds that the user does not have the required ´Authentication Context Class Reference (ACR)´ and, based on the
requirements specified by the operator, it sends a 302 FOUND HTTP response to the caller.
This operation will redirect the user to the `}<inlineCode parentName="p">{`/authorize`}</inlineCode>{` endpoint of the Identity Provider.`}</p>
    <p>{`Once the user authenticates successfully, it is redirected to your frontend application, where you can, in total
independence, take care of the user experience while you communicate to Ocelot the required `}<inlineCode parentName="p">{`code`}</inlineCode>{` and `}<inlineCode parentName="p">{`state`}</inlineCode>{` through
a POST request.`}</p>
    <p>{`Through this information, Ocelot will be able to communicate with the Identity Provider and exchange the code for an
Access and ID tokens. This information will then be used to build the Ocelot token that will be used to authorize
the call against your business services.`}</p>
    <h2 {...{
      "id": "azure-setup"
    }}>{`Azure setup`}</h2>
    <p>{`Azure AD implements OIDC. To ensure your application can use Azure AD as its identity provider,
you will have to register your application in your Azure tenant. You can do that by adding an `}<inlineCode parentName="p">{`App Registration`}</inlineCode>{`
as explained `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app"
      }}>{`here`}</a></p>
    <p>{`Next, you will have to store your `}<inlineCode parentName="p">{`App Client Secret`}</inlineCode>{` to the key vault Ocelot have access to. You can choose any id that
comply with your naming standards, as you will be able to specify it during the ACR registration.`}</p>
    <p>{`That's practically it! If your application needs exclusively standard claims contained in the Identity Token and in
the object returned from the User Info endpoint you can then proceed with configuring Ocelot.`}</p>
    <p>{`If you would like to allow Azure AD to map extra claims for your application, you can do that by configuring
`}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims"
      }}>{`optional claims`}</a>{`.`}</p>
    <h2 {...{
      "id": "ocelot-configuration"
    }}>{`Ocelot configuration`}</h2>
    <p>{`Now the last thing that is left to do is to create an ACR in Ocelot.`}</p>
    <p>
You can do this through the Ocelot GUI or through the Create ACR API documented <Link to="/src-markdowns-5-2-api-docs" mdxType="Link">here</Link>
    </p>
    <h2 {...{
      "id": "granular-control-and-internal-mapping"
    }}>{`Granular control and internal mapping`}</h2>
    <p>{`Once you have defined an ACR, you can define role-mapping rules based on the claims contained in the ID Token or the object returned from the User Info endpoint.`}</p>
    <p>
For more information on how to do that you can look at the Roles APIs <Link to="/src-markdowns-5-2-api-docs" mdxType="Link">here</Link>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      